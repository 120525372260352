import { Box, Container, Grid, Stack, useMediaQuery } from "@mui/material";
import { GraphQLError } from "graphql";
import moment from "moment";
import { parse } from "query-string";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { WithApolloClient, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import {
  ADD_COUPON,
  ADD_TO_CART,
  CHANGE_SUBSCRIPTION_PLAN,
  CLEAR_CART,
  ICartAction,
  ICartItem,
  LOAD_DATA_FROM_LOCAL_STORAGE,
  SET_TOTAL
} from "../../actionReducers/Cart";
import {
  ICreditCard,
  IFinishedCheckout,
  PaymentMode,
  PaymentType,
  TabsCheckoutKey
} from "../../actionReducers/Checkout";
import {
  CLEAN_CREDIT_CARD_FAST_CHECKOUT,
  CLEAN_FAST_CHECKOUT,
  IFastCheckoutAction,
  IPersonalDataFastCheckout,
  SET_CAMPAIGN_FAST_CHECKOUT,
  SET_COUPON_FAST_CHECKOUT,
  SET_CURRENT_TAB_FAST_CHECKOUT,
  SET_DELIVERED_ADDRESS_FAST_CHECKOUT,
  SET_FINISHED_PRODUCT_FAST_CHECKOUT,
  SET_HAS_DISCOUNT_FAST_CHECKOUT,
  SET_NEW_ACCOUNT_FAST_CHECKOUT,
  SET_ORIGIN_FAST_CHECKOUT,
  SET_PAYMENT_MODE_FAST_CHECKOUT,
  SET_PERSONAL_DATA_ALREADY_SAVED,
  SET_RECURRENCE_VALUE_FAST_CHECKOUT,
  SET_TYPE_PAYMENT_FAST_CHECKOUT,
  SET_USER_PERSONAL_DATA
} from "../../actionReducers/FastCheckout";
import {
  IGeneralAction,
  SET_LOADING,
  SET_MISSING_ORDER_ID,
  SET_MISSING_PAYMENT,
  SET_ORDER_CONFIRMATION,
  SET_TRANSACTION_ID
} from "../../actionReducers/General";
import { IUserAction, LOGOUT_USER } from "../../actionReducers/User";
import animation from "../../assets/img/allugator.svg";
import {
  CAMPAIGN,
  CONTENT,
  EXTERNAL_CHANNEL_FAST_CHECKOUT,
  EXTERNAL_LEAD_ID_FAST_CHECKOUT,
  EXTERNAL_VENDOR_FAST_CHECKOUT,
  INTER_BANK_CLICK_REF,
  MEDIUM,
  NOTICE_FAST_CHECKOUT,
  ORIGIN_FAST_CHECKOUT,
  PAYMENTMODE_FAST_CHECKOUT,
  SOURCE,
  TERM,
  UPGRADE_ID_FAST_CHECKOUT,
  VENDOR_FAST_CHECKOUT
} from "../../boot/constants";
import Header from "../../components/Pages/Checkout/Header";
import TabPanel from "../../components/Pages/Checkout/TabPanel";
import WhatsappBalloon from "../../components/whatsappBalloon";
import { Pipeline, Stage } from "../../constants/hubspotDeals";
import {
  MUTATION_FAST_CHECKOUT,
  MUTATION_FAST_CHECKOUT_PICPAY
} from "../../graphql/mutations/checkoutOrders";
import {
  IMutationCreateOrUpdate,
  MUTATION_CREATE_OR_UPDATE
} from "../../graphql/mutations/users";
import {
  IQueryVerifyCampaign,
  QUERY_VERIFY_CAMPAIGN
} from "../../graphql/queries/campaign";
import {
  IQueryCalculateOrderTotalPrice,
  QUERY_CALCULATE_ORDER_TOTAL_PRICE
} from "../../graphql/queries/checkoutOrders";
import {
  IQueryCheckCoupon,
  QUERY_CHECK_COUPON
} from "../../graphql/queries/coupons";
import { IPublicCoupon } from "../../graphql/types/coupons";
import { IProduct } from "../../graphql/types/products";
import { IUserAddress } from "../../graphql/types/users";
import CPFValidation from "../../helpers/CPFValidation";
import Cookies from "../../helpers/Cookies";
import { calculateFullPrice, getPriceByPlan, LoyaltyPlan } from "../../helpers/calculateSubtotal";
import formatProductDataLayer from "../../helpers/formatProductDataLayer";
import { mountName } from "../../helpers/mountName";
import resetMoment from "../../helpers/resetMoment";
import { validationDocument } from "../../helpers/validationDocument";
import {
  createDealHubspotFromData,
  getDealHubspot,
  updateDealHubspot
} from "../../services/UserApi";
import { IAppState } from "../../store";
import { validatorAddressSchema } from "../../validators/validatorAddress";
import ValidatorCreditCard from "../../validators/validatorCreditCard";
import { validatorPersonalDataFastCheckout } from "../../validators/validatorPersonalDataFastCheckout";
import BackStepButton from "./components/BackStepButton";
import ChooseSubscriptionPlan from "./components/ChooseSubscriptionPlan";
import DeliveredAddress from "./components/DeliveredAddress";
import Footer from "./components/Footer";
import NextStepButton from "./components/NextStepButton";
import PaymentStep from "./components/PaymentStep";
import PersonalDataFastCheckout, {
  IFormikPersonalDataFastCheckout
} from "./components/PersonalDataFastCheckout";
import ProductShowcase from "./components/ProductShowcase";
import ResumeCheckout from "./components/ResumeCheckout";
import TabMenu from "./components/TabMenu";
import { AnimationContainer } from "./style";
import { googleTag } from "../../helpers/analytics";
import { useAnalytics } from "../../hooks/useAnalytics";
import { interShopping } from "../../helpers/interShoppingAffiliate";
import axios from "axios";
import Config from "../../config";

interface IProps {
  currentTab: TabsCheckoutKey;
  loading: boolean;
  cart: any;
  valueForDataLayer: number;
  paymentType: PaymentType;
  personalDataAlreadySaved: boolean;
  creditCard: ICreditCard;
  deliveryAddress?: IUserAddress;
  personalData?: IPersonalDataFastCheckout;
  cardInfo: any;
  total: number;
  subTotal: number;
  finishedCheckout: IFinishedCheckout;
  campaign?: string;
  logged?: boolean;
  setPersonalData: ActionCreator<IFastCheckoutAction>;
  addCouponCheckout: ActionCreator<IFastCheckoutAction>;
  addOriginCheckout: ActionCreator<IFastCheckoutAction>;
  changeTab: ActionCreator<IFastCheckoutAction>;
  setDeliveredAddress: ActionCreator<IFastCheckoutAction>;
  cleanCheckout: ActionCreator<IFastCheckoutAction>;
  setHasDiscount: ActionCreator<IFastCheckoutAction>;
  setCampaign: ActionCreator<IFastCheckoutAction>;
  setFinishedProduct: ActionCreator<IFastCheckoutAction>;
  cleanCreditCard: ActionCreator<IFastCheckoutAction>;
  setNewAccount: ActionCreator<IFastCheckoutAction>;
  setPersonalDataAlreadySaved: ActionCreator<IFastCheckoutAction>;
  setLoading: ActionCreator<IGeneralAction>;
  setMissingPayment: ActionCreator<IGeneralAction>;
  setMissingOrderId: ActionCreator<IGeneralAction>;
  setTransactionId: ActionCreator<IGeneralAction>;
  setOrderConfirmation: ActionCreator<IGeneralAction>;
  setTotal: ActionCreator<ICartAction>;
  addToCart: ActionCreator<ICartAction>;
  changeSubscriptionPlan: ActionCreator<ICartAction>;
  addCoupon: ActionCreator<ICartAction>;
  cleanCart: ActionCreator<ICartAction>;
  loadDataFromLocalStorage: ActionCreator<ICartAction>;
  logout: ActionCreator<IUserAction>;
  setTypePayment: ActionCreator<IFastCheckoutAction>;
  setRecValue: ActionCreator<IFastCheckoutAction>;
  setPaymentModeValue: ActionCreator<IFastCheckoutAction>;
}

interface ICampaign {
  campaign: string;
  productName: string;
  couponName?: string;
  name: string;
  email: string;
  phone?: string;
  originPaymentMode?: string;
  origin?: string;
  document?: string;
  birthdate?: string;
  loyaltyPlan?: LoyaltyPlan;
}

interface ICreateDealProps {
  productName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  currentBrand?: string;
  idealPayment?: string;
  changeSoon?: string;
  originFunnel?: string;
  externalChannel?: string;
  externalVendor?: string;
  leadId?: string;
  nestleCheck?: boolean
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export interface IInvalidDocument {
  status: boolean;
  message?: string;
}

const FastCheckout = ({
  currentTab,
  location,
  client,
  loading,
  match: { params },
  cart: { items, coupon },
  paymentType,
  creditCard,
  history,
  deliveryAddress,
  personalData,
  cardInfo,
  total,
  finishedCheckout,
  campaign,
  logged,
  setLoading,
  setNewAccount,
  addToCart,
  changeSubscriptionPlan,
  setPersonalData,
  addCoupon,
  cleanCart,
  changeTab,
  setDeliveredAddress,
  setHasDiscount,
  setTotal,
  setFinishedProduct,
  setMissingPayment,
  setMissingOrderId,
  setTransactionId,
  setOrderConfirmation,
  cleanCreditCard,
  loadDataFromLocalStorage,
  setCampaign,
  cleanCheckout,
  logout,
  addCouponCheckout,
  addOriginCheckout,
  setTypePayment,
  setRecValue,
  setPaymentModeValue,
}: IProps &
  RouteComponentProps<{ campaign: string }> &
  WithApolloClient<{}>) => {
  const [userData, setUserData] = useState<IFormikPersonalDataFastCheckout>();
  const [addressData, setAddressData] = useState<IUserAddress>();
  const [billingAddressData, setBillingAddressData] =useState<IUserAddress>()
  const [nextStepDisabled, setNextStepDisabled] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [
    invalidDocument,
    setInvalidDocument
  ] = useState<IInvalidDocument | null>({ status: false });
  const [gtmCookie, setGtmCookie] = useState<any>(null);
  const [hasCardInfo, setHasCardInfo] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(!campaign);
  const [origin, setOrigin] = useState<string>("");
  const [resultSend, setResultSend] = useState<any>();
  const [liveloScore, setLiveloScore] = useState<number>(0);
  const [liveloClubScore, setLiveloClubScore] = useState<number>(0);
  const [valueForDataLayer, setValueForDataLayer] = useState<number>(0);
  const [funnel, setFunnel] = useState<string>("");
  const [paymentModeValues, setPaymentModeValues] = useState({
    yearlyValue: 0,
    semesterValue: 0,
    quarterlyValue: 0,
    recurrenceValue: 0,
  });
  const [paymentModeAvailability, setPaymentModeAvailability] = useState<
    Boolean
  >();
  const [checkoutFinished, setCheckoutFinished] = useState<boolean>(false);
  const [
    adminPaymentModeAvailability,
    setAdminPaymentModeAvailability
  ] = useState<Boolean>(false);
  const [paymentError, setPaymentError] = useState<Boolean>(false);
  const [paymentDay, setPaymentDay] = useState<string>("5");
  const [dataId, setDataId] = useState<string>("");
  const [ablePreviousStep, setAblePreviousStep] = useState<boolean>(false);
  const [payloadFCApi, setPayloadFCApi] = useState<any>(null);
  const analytics = useAnalytics();
  const [loyaltyPlanSetted, setLoyaltyPlanSetted] = useState<string>("");

  const getPaymentMode = () => {
    const paymentMode = Cookies.get(PAYMENTMODE_FAST_CHECKOUT);

    if (!paymentMode || ["undefined", "null"].includes(paymentMode)) {
      setChosenPaymentMode(PaymentMode.YEARLY);
      return PaymentMode.YEARLY;
    }

    setChosenPaymentMode(paymentMode);
    return paymentMode;
  };

  const [chosenPaymentMode, setChosenPaymentMode] = useState<PaymentMode>();

  const liveloOrigin = origin === "livelo";

  const getGtmCookie = () => {
    if (
      document.getElementById("gtm-cookie-input") &&
      (document.getElementById("gtm-cookie-input") as HTMLInputElement).value
    ) {
      setGtmCookie(
        (document.getElementById("gtm-cookie-input") as HTMLInputElement).value
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setDisplayAnimation(false);
    }, 3000);

    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.pagar.me/pagarme-js/4.5/pagarme.min.js"
    );
    script.setAttribute("async", "async");
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (
      finishedCheckout &&
      finishedCheckout.blockCheckout &&
      Object.keys(finishedCheckout).length &&
      campaign
    )
      history.push("/");
  }, [finishedCheckout]);

  useLayoutEffect(() => {
    getGtmCookie();
  }, [
    (document.getElementById("gtm-cookie-input") as HTMLInputElement)?.value
  ]);

  useEffect(() => {
    if (!campaign || location.search) {
      if (logged) logout();
      cleanCheckout();
      cleanCart();
    }
    loadDataFromLocalStorage();
    getQueryParams();
  }, [location.search, payloadFCApi]);

  useEffect(() => {
    if (selectedProduct && coupon) {
      calculateTotal(coupon.slug);
    }
    if (items && !selectedProduct) {
      selectProduct();
    }
  }, [items]);

  useEffect(() => {
    if (selectedProduct && coupon) {
      calculateTotal(coupon.slug);
      const data = queryParams()
      const fetchAndSetValues = async () => {
        if (selectedProduct && coupon) {
          try {
            const [
              yearlyValue,
              semesterValue,
              quarterlyValue,
              recurrenceValue,
            ] = await Promise.all([
              calculateTotalValuePaymentMode(coupon.slug, PaymentMode.YEARLY, data.loyaltyPlan),
              calculateTotalValuePaymentMode(coupon.slug, PaymentMode.SEMESTER, data.loyaltyPlan),
              calculateTotalValuePaymentMode(coupon.slug, PaymentMode.QUARTERLY, data.loyaltyPlan),
              calculateTotalValuePaymentMode(coupon.slug, PaymentMode.RECURRENCE, data.loyaltyPlan)
            ]);

            setPaymentModeValues({
              yearlyValue,
              semesterValue,
              quarterlyValue,
              recurrenceValue,
            });
          } catch (error) {
            toast.error("Erro ao buscar descontos.");
          }
        }
      };
      fetchAndSetValues();
    }
    
    const fetchAndSetValues = async () => {
      const data = queryParams()
      setLoyaltyPlanSetted(data?.loyaltyPlan?.toString())
      const [yearlyValue, semesterValue, quarterlyValue, recurrenceValue] = await Promise.all([
        calculateTotalValuePaymentMode("", PaymentMode.YEARLY, data.loyaltyPlan),
        calculateTotalValuePaymentMode("", PaymentMode.SEMESTER, data.loyaltyPlan),
        calculateTotalValuePaymentMode("", PaymentMode.QUARTERLY, data.loyaltyPlan),
        calculateTotalValuePaymentMode("", PaymentMode.RECURRENCE, data.loyaltyPlan)
      ]);

      setPaymentModeValues({
        yearlyValue,
        semesterValue,
        quarterlyValue,
        recurrenceValue
      });
    };
    fetchAndSetValues();
  }, [selectedProduct, coupon]);

  useEffect(() => {
    setRecValue(paymentModeValues.recurrenceValue);
  }, [paymentModeValues.recurrenceValue])

  useEffect(() =>  {
    setPaymentModeValue(chosenPaymentMode)
  }, [chosenPaymentMode])

  const calculateLiveloScore = (value: number) => {
    const totalScore = Math.floor(
      value * Number(process.env.RAZZLE_PARIDADE_LIVELO)
    );
    const totalScoreClub = Math.floor(
      value * Number(process.env.RAZZLE_PARIDADE_LIVELO_CLUB)
    );

    setLiveloScore(totalScore);
    setLiveloClubScore(totalScoreClub);
  };

  useEffect(() => {
    calculateLiveloScore(total);
  }, [total]);

  useEffect(() => {
    if (cardInfo?.hash) {
      setHasCardInfo(true);
    }

    if (
      creditCard.cardInfo &&
      hasCardInfo &&
      currentTab === TabsCheckoutKey.PAYMENT
    ) {
      changeTab(TabsCheckoutKey.SUMMARY);
      handleDisabledButton();
    }
  }, [cardInfo]);

  const selectProduct = async () => {
    if (!items || !items.length || !items[0].product) return;
    const product = items[0].product;
    setSelectedProduct(product);
  };

  useEffect(() => {
    if (!paymentType && currentTab === TabsCheckoutKey.SUMMARY) {
      changeTab(TabsCheckoutKey.PAYMENT);
      return;
    }
    if (!paymentType && currentTab === TabsCheckoutKey.PAYMENT) {
      // const isPicpayPayment =
      //   getPaymentMode() === PaymentMode.YEARLY &&
      //   origin?.toLowerCase() === "picpay";
      setTypePayment(PaymentType.CREDIT_CARD);
      return;
    }
    handleDisabledButton();
  }, [ablePreviousStep, userData, addressData, currentTab, paymentType, creditCard]);

  async function validateCustomerData(customer: any) {
    const requiredFields = {
      fullName: 'Nome completo',
      email: 'Email',
      document: 'Documento',
      birthDate: 'Data de nascimento',
      phone: 'Telefone'
    };

    const missingFields = Object.keys(requiredFields)
      .filter((field) => !customer[field])
      .map((field) => requiredFields[field]);

    if (missingFields.length > 0) {
      toast.error(`Por favor, preencha os seguintes campos: ${missingFields.join(', ')}`);
      return false;
    }

    if (!moment(customer.birthDate).isValid()) {
      toast.error('Data de nascimento inválida, por favor, preencha os dados novamente');
      return false;
    }

    if (!CPFValidation(payloadFCApi.customer.document)) {
      toast.error('CPF Inválido')
      return false;
    }

    try {
      await validationDocument({
        email: payloadFCApi.customer.email,
        document: payloadFCApi.customer.document
      });
    } catch (error) {
      toast.error('Dados de CPF inválidos, por favor verifique novamente');
      return false;
    }
    return true;
  }

  function validateAddressData(address: any) {
    const requiredFields = {
      code: 'CEP',
      street: 'Rua',
      city: 'Cidade',
      state: 'Estado',
      number: 'Número',
      neighborhood: 'Bairro'
    };

    if(!address){
      toast.error('Endereço não informado, por favor, preencha os dados.');
      return false;
    }

    const missingFields = Object.keys(requiredFields)
      .filter((field) => !address[field])
      .map((field) => requiredFields[field]);

    if (missingFields.length > 0) {
      toast.error('Por favor, preencha os campos de endereço.');
      return false;
    }

    if(address.code.length < 8){
      toast.error('CEP informado é inválido, por favor, verifique novamente');
      return false;
    }

    return true;
  }

  const handlePaymentStep = async () => {
    if (!await validateCustomerData(payloadFCApi.customer)) {
      changeTab(TabsCheckoutKey.PERSONAL_DATA);
      return;
    }

    if (!validateAddressData(payloadFCApi.shippingAddress)) {
      changeTab(TabsCheckoutKey.ADDRESS);
      return;
    }

    try {
      await validationDocument({
        email: payloadFCApi.customer.email,
        document: payloadFCApi.customer.document
      });

      const { phone, birthDate: birthdate, document, email } = payloadFCApi.customer;
      const { firstName, lastName } = mountName(payloadFCApi.customer.fullName);

      setPersonalData({
        phone: phone || userData.phone,
        birthdate: birthdate || userData.birthdate,
        document: document || userData.document,
        email: email || userData.email,
        firstName,
        lastName,
      });

      await updatePersonalData({ isChatBotInfo: true });

      setDeliveredAddress({
        code: payloadFCApi.shippingAddress?.code || addressData?.code,
        street: payloadFCApi.shippingAddress?.street || addressData?.street,
        city: payloadFCApi.shippingAddress?.city || addressData?.city,
        state: payloadFCApi.shippingAddress?.state || addressData?.state,
        number: payloadFCApi.shippingAddress?.number || addressData?.number,
        neighborhood: payloadFCApi.shippingAddress?.neighborhood || addressData?.neighborhood,
        complement: payloadFCApi.shippingAddress?.complement || addressData?.complement || '',
      });

      changeTab(TabsCheckoutKey.PAYMENT);

    } catch (err) {
      setInvalidDocument({ status: true, message: err.message });
    }
  }

  const handleNextStep = async () => {
    switch (currentTab) {
      case TabsCheckoutKey.PERSONAL_DATA:
        try {
          if (!CPFValidation(userData.document)) {
            setInvalidDocument({
              status: true,
              message: "Número de CPF inválido."
            });
            return;
          }
          await validationDocument({
            email: userData.email,
            document: userData.document
          });

          const { phone, birthdate, document, email, nestleCheck } = userData;
          const { firstName, lastName } = mountName(userData.name);

          setPersonalData({
            firstName,
            lastName,
            phone,
            birthdate,
            document,
            email,
            nestleCheck,
          });

          await updatePersonalData({ isChatBotInfo: false });
          // analytics.identify({ email, phone, firstName, lastName, document });
          // analytics.track("Checkout Step Completed", {
          //   step: "Personal Data",
          //   option: "Next Step",
          //   product: items[0].product.name,
          //   value: valueForDataLayer,
          //   currency: "BRL",
          //   paymentMode: chosenPaymentMode,
          //   paymentType: paymentType,
          //   address: addressData,
          //   coupon: coupon?.slug
          // });
          changeTab(TabsCheckoutKey.ADDRESS);
        } catch (err) {
          setInvalidDocument({ status: true, message: err.message });
        }
        break;
      case TabsCheckoutKey.ADDRESS:
        setDeliveredAddress(addressData);

        // analytics.track("Checkout Step Completed", {
        //   step: "Address",
        //   option: "Next Step",
        //   product: items[0].product.name,
        //   value: valueForDataLayer,
        //   currency: "BRL",
        //   paymentMode: chosenPaymentMode,
        //   paymentType: paymentType,
        //   address: addressData,
        //   coupon: coupon?.slug
        // });
        changeTab(TabsCheckoutKey.PAYMENT);
        break;
      case TabsCheckoutKey.PAYMENT:
        // analytics.track("Checkout Step Completed", {
        //   step: "Payment",
        //   option: "Next Step",
        //   product: items[0].product.name,
        //   value: valueForDataLayer,
        //   currency: "BRL",
        //   paymentMode: chosenPaymentMode,
        //   paymentType: paymentType,
        //   address: addressData,
        //   coupon: coupon?.slug
        // });
        changeTab(TabsCheckoutKey.SUMMARY);
        break;
      case TabsCheckoutKey.SUMMARY:
        await submit();
        break;
      default:
        break;
    }
    return;
  };

  useEffect(() => {
    handleDisabledButton();
  }, [checkoutFinished]); 

  const handleDisabledButton = async () => {
    switch (currentTab) {
      case TabsCheckoutKey.PERSONAL_DATA:
        if (!userData || userData?.document?.length < 11) {
          setNextStepDisabled(true);
          return;
        }
        if (ablePreviousStep || (await validatorPersonalDataFastCheckout.isValid(userData))) {
          setNextStepDisabled(false);
          return;
        }
        setNextStepDisabled(true);
      case TabsCheckoutKey.ADDRESS:
        if (ablePreviousStep || (await validatorAddressSchema.isValid(addressData))) {
          setNextStepDisabled(false);
          return;
        }
        setNextStepDisabled(true);
      case TabsCheckoutKey.PAYMENT:
        if (
          paymentType &&
          (paymentType === PaymentType.PIX ||
            paymentType === PaymentType.PICPAY ||
            (await ValidatorCreditCard.isValid(creditCard)))
        ) {
          setNextStepDisabled(false);
          return;
        }
        setNextStepDisabled(true);
        break;
      case TabsCheckoutKey.SUMMARY:
        if(checkoutFinished) {
          setNextStepDisabled(false);
          return;
        }
        setNextStepDisabled(true);
        break;
    }
  };

  const updatePersonalData = async ({ isChatBotInfo } : { isChatBotInfo: boolean }) => {
    try {
      const { phone, document, email, nestleCheck } = payloadFCApi && isChatBotInfo ? payloadFCApi.customer : userData
      const birthdate = payloadFCApi && isChatBotInfo ? payloadFCApi.customer.birthDate : userData.birthdate;
      const { firstName, lastName } = mountName(payloadFCApi && isChatBotInfo ? payloadFCApi.customer.fullName : userData.name);

      const {
        data: { createOrUpdate }
      } = await client.mutate<IMutationCreateOrUpdate>({
        mutation: MUTATION_CREATE_OR_UPDATE,
        variables: {
          firstName,
          lastName,
          phone,
          document,
          email,
          birthday: birthdate ? moment(birthdate).toDate() : null,
        }
      });
      if (createOrUpdate) {
        const hubspotDeal = await getDealHubspot(
          Pipeline.FAST_CHECKOUT,
          createOrUpdate.token
        );

        const data = queryParams();

        const { currentBrand, idealPayment, changeSoon, originFunnel } = data;

        const externalChannel = Cookies.get(EXTERNAL_CHANNEL_FAST_CHECKOUT);
        const externalVendor = Cookies.get(EXTERNAL_VENDOR_FAST_CHECKOUT);
        const leadId = Cookies.get(EXTERNAL_LEAD_ID_FAST_CHECKOUT);
        const utm_source = Cookies.get(SOURCE);
        const utm_medium = Cookies.get(MEDIUM);
        const utm_campaign = Cookies.get(CAMPAIGN);
        const utm_term = Cookies.get(TERM);
        const utm_content = Cookies.get(CONTENT);

        if (hubspotDeal) {
          updateDeal({
            firstName,
            lastName,
            token: createOrUpdate.token,
            contactData: { currentBrand, idealPayment, changeSoon },
            dealData: {
              funil_origem: originFunnel,
              external_channel: externalChannel || null,
              external_vendor: externalVendor,
              lead_id: leadId
            },
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            utm_term: utm_term,
            utm_content: utm_content,
            nestleCheck,
          });
        } else {
          createDeal({
            firstName,
            lastName,
            email,
            phone,
            productName: items[0].product.name,
            currentBrand,
            idealPayment,
            changeSoon,
            originFunnel,
            externalChannel,
            externalVendor,
            leadId,
            nestleCheck,
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            utm_term: utm_term,
            utm_content: utm_content,
          });
        }

        setPersonalData({
          firstName,
          lastName,
          phone,
          birthdate,
          document,
          email,
          nestleCheck,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content
        });
        if (createOrUpdate.action === "account_created") {
          setNewAccount(true);
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Erro ao alterar dados do usuário.");
      return;
    }
  };

  const queryParams = () => {
    const queryParams = parse(location.search);
    if (queryParams && Object.keys(queryParams).length) {
      const {
        produto: productName,
        nome: name,
        telefone: phone,
        cupom: couponName,
        email,
        marcaAtual: currentBrand,
        pagamentoIdeal: idealPayment,
        trocarCelularEmBreve: changeSoon,
        FunilOrigem: originFunnel,
        canalExterno: externalChannel,
        vendedorExterno: externalVendor,
        origin,
        vendor,
        interClickRef: interClickRef,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        utm_term: utm_term,
        utm_content: utm_content,
        paymentMode: originPaymentMode,
        lead_id: leadId,
        upgradeId: upgradeId,
        notice: notice,
        loyaltyPlan,
        dataId,
      } = queryParams;

      setCookie(EXTERNAL_CHANNEL_FAST_CHECKOUT, externalChannel);
      setCookie(EXTERNAL_VENDOR_FAST_CHECKOUT, externalVendor);
      setCookie(EXTERNAL_LEAD_ID_FAST_CHECKOUT, leadId);
      setCookie(UPGRADE_ID_FAST_CHECKOUT, upgradeId);
      setCookie(NOTICE_FAST_CHECKOUT, notice);
      setCookie(INTER_BANK_CLICK_REF, interClickRef);
      setCookie(SOURCE, utm_source);
      setCookie(MEDIUM, utm_medium);
      setCookie(CAMPAIGN, utm_campaign);
      setCookie(TERM, utm_term);
      setCookie(CONTENT, utm_content);

      if (dataId) {
        setDataId(dataId)
      }

      return {
        productName,
        name,
        phone,
        couponName,
        email,
        currentBrand,
        idealPayment,
        changeSoon,
        originFunnel,
        origin,
        vendor,
        originPaymentMode,
        loyaltyPlan,
        dataId
      };
    }
    return null;
  };

  const setCookie = (name: string, value: string) => {
    const valuesAreNotAllowed = ["null", "undefined", ""];

    const isValid = v => v && !valuesAreNotAllowed.includes(v.trim());

    if (isValid(value)) {
      Cookies.set(name, value.trim());
    }
  };

  const getQueryParams = async () => {
    const data = queryParams();
    if (!data) return;
    const {
      productName,
      couponName,
      name,
      phone,
      email,
      currentBrand,
      idealPayment,
      changeSoon,
      originFunnel,
      vendor,
      originPaymentMode,
      origin,
      loyaltyPlan,
    } = data;

    if (productName) {
      Cookies.set(PAYMENTMODE_FAST_CHECKOUT, originPaymentMode, { path: "/" });
      Cookies.set(VENDOR_FAST_CHECKOUT, vendor, { path: "/" });

      await verifyCampaign({
        campaign: params.campaign,
        productName,
        couponName,
        name: payloadFCApi ? payloadFCApi.customer.fullName : name,
        phone: payloadFCApi ? payloadFCApi.customer.phone : phone,
        email: payloadFCApi ? payloadFCApi.customer.email : email,
        document: payloadFCApi ? payloadFCApi.customer.document : '',
        birthdate: payloadFCApi ? payloadFCApi.customer.birthDate : '',
        originPaymentMode,
        origin,
        loyaltyPlan,
      });
      setFunnel(originFunnel);
      if (email || name || phone) {
        const externalChannel = Cookies.get(EXTERNAL_CHANNEL_FAST_CHECKOUT);
        const externalVendor = Cookies.get(EXTERNAL_VENDOR_FAST_CHECKOUT);
        const leadId = Cookies.get(EXTERNAL_LEAD_ID_FAST_CHECKOUT);
        await createDealInitial({
          email,
          name,
          phone,
          product: productName,
          currentBrand,
          idealPayment,
          changeSoon,
          originFunnel,
          externalChannel,
          externalVendor,
          leadId
        });
      }
      return;
    }
    return;
  };

  const createDealInitial = async ({
    name,
    email,
    phone,
    product,
    currentBrand,
    idealPayment,
    changeSoon,
    originFunnel,
    externalChannel,
    externalVendor,
    leadId
  }) => {
    const { firstName, lastName } = mountName(name);
    await createDeal({
      firstName,
      lastName,
      email,
      phone,
      productName: product,
      currentBrand,
      idealPayment,
      changeSoon,
      originFunnel,
      externalChannel,
      externalVendor,
      leadId
    });
  };

  const verifyCampaign = async ({
    campaign,
    productName,
    couponName,
    name,
    phone,
    email,
    document,
    birthdate,
    originPaymentMode,
    origin,
    loyaltyPlan,
  }: ICampaign) => {
    const externalVendor = Cookies.get(EXTERNAL_VENDOR_FAST_CHECKOUT);
    try {
      setLoading(true);
      const {
        data: { verifyCampaign }
      } = await client.query<IQueryVerifyCampaign>({
        query: QUERY_VERIFY_CAMPAIGN,
        variables: {
          id: campaign,
          product: productName,
          sellerId: externalVendor !== "undefined" ? externalVendor : null,
          coupon: couponName
        }
      });

      if (verifyCampaign && verifyCampaign.product) {
        const selectDate = resetMoment(moment()).add(
          verifyCampaign.product.deadline || 35,
          "day"
        );
        let isAdminPaymentMode = false;

        if (
          originPaymentMode &&
          ((origin?.toLowerCase() === "admin" || origin?.toLowerCase() === 'new-site') &&
            verifyCampaign.paymentModeAvailability === null) || origin?.toLowerCase() === 'catalog'
        ) {
          setAdminPaymentModeAvailability(true);
          isAdminPaymentMode = true;
        }

        const paymentCheck = getPaymentMode() ?? PaymentMode.YEARLY;

        const paymentMode =
          isAdminPaymentMode || verifyCampaign.paymentModeAvailability
            ? paymentCheck
            : PaymentMode.YEARLY;

        setChosenPaymentMode(paymentMode);
        const { firstName, lastName } = mountName(name);
        setPersonalData({ firstName, lastName, email, phone, document, birthdate });
        addToCart({
          product: verifyCampaign.product,
          endDate: selectDate.clone().add(365, "days"),
          startDate: selectDate,
          quantity: 1,
          paymentMode: paymentMode,
          loyaltyPlan: loyaltyPlan,
        });

        Cookies.set(PAYMENTMODE_FAST_CHECKOUT, paymentMode, { path: "/" });

        setCampaign(campaign);
        setPaymentModeAvailability(
          verifyCampaign.paymentModeAvailability || false
        );

        Cookies.set(ORIGIN_FAST_CHECKOUT, verifyCampaign.origin, { path: "/" });
        if (verifyCampaign.coupon) {
          addCoupon(verifyCampaign.coupon);
          addCouponCheckout(verifyCampaign.coupon);
        }

        setOrigin(verifyCampaign.origin);
        addOriginCheckout(verifyCampaign.origin);
        setCampaign(verifyCampaign.id);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Campanha inválida, por favor verifique seus dados.");
      history.push("/");
    }
  };

  const updateDeal = async ({
    firstName,
    lastName,
    token,
    contactData,
    dealData,
    nestleCheck,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content
  }) => {
    const dealName = `${firstName} ${lastName}`;
    const originPartner = Cookies.get(ORIGIN_FAST_CHECKOUT);
    await updateDealHubspot(
      Pipeline.FAST_CHECKOUT,
      dealName,
      token,
      {
        originPartner, nestleCheck, ...contactData,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content
      },
      dealData,
    );
  };

  const createDeal = async ({
    productName,
    firstName,
    lastName,
    phone,
    email,
    currentBrand,
    idealPayment,
    changeSoon,
    originFunnel,
    externalChannel,
    externalVendor,
    leadId,
    nestleCheck,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content
  }: ICreateDealProps) => {
    const originPartner = Cookies.get(ORIGIN_FAST_CHECKOUT);
    await createDealHubspotFromData({
      pipeline: Pipeline.FAST_CHECKOUT,
      dealstage: Stage.FAST_CHECKOUT,
      personalData: {
        email,
        phone,
        firstName,
        lastName,
        product: productName,
        currentBrand,
        idealPayment,
        changeSoon,
        originPartner,
        nestleCheck,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content
      },
      dealData: {
        aparelho: productName,
        fast_checkout: true,
        funil_origem: originFunnel,
        external_channel: externalChannel || null,
        external_vendor: externalVendor || null,
        lead_id: leadId
      },
      campaign
    });
  };

  const checkCoupon = async (slug: string) => {
    if (!slug) return;
    try {
      const {
        data: { checkCoupon }
      } = await client.query<IQueryCheckCoupon>({
        query: QUERY_CHECK_COUPON,
        variables: {
          slug,
          billet: slug == PaymentType.PIX.toUpperCase() ? true : false
        }
      });
      if (checkCoupon && !checkCoupon.flags.disable) {
        if (checkCoupon.amount > 0) {
          setHasDiscount(true);
          return coupon;
        }
      }
    } catch (e) {
      e.graphQLErrors.map((error: GraphQLError) => toast.error(error.message));
    }
  };

  const calculateTotal = async (couponSlug: string) => {
    if (!couponSlug) {
      setHasDiscount(false);
    }

    const coupon = await checkCoupon(couponSlug);
    const data = queryParams()
    const {
      data: { calculateOrderTotalPrice }
    } = await client.query<IQueryCalculateOrderTotalPrice>({
      query: QUERY_CALCULATE_ORDER_TOTAL_PRICE,
      variables: {
        items: items.map(i => ({
          product: i.product._id,
          quantity: i.quantity,
          paymentMode: i.paymentMode,
          endDate: i.endDate,
          startDate: i.startDate,
        })),
        couponSlug: (coupon && coupon.slug) || "",
        paymentMode: chosenPaymentMode,
        loyaltyPlan: data.loyaltyPlan,
      }
    });
    if (calculateOrderTotalPrice) {
      setValueForDataLayer(calculateOrderTotalPrice);
      setTotal(calculateOrderTotalPrice);
    }
  };

  const calculateTotalValuePaymentMode = async (
    couponSlug: string,
    paymentMode: PaymentMode,
    loyaltyPlan?: string,
  ) => {
    const coupon = await checkCoupon(couponSlug);
    const {
      data: { calculateOrderTotalPrice }
    } = await client.query<IQueryCalculateOrderTotalPrice>({
      query: QUERY_CALCULATE_ORDER_TOTAL_PRICE,
      variables: {
        items: items.map(i => ({
          product: i.product._id,
          quantity: i.quantity,
          paymentMode: i.paymentMode,
          endDate: i.endDate,
          startDate: i.startDate,
        })),
        couponSlug: (coupon && coupon.slug) || "",
        paymentMode,
        loyaltyPlan,
      }
    });
    return calculateOrderTotalPrice;
  };

  useEffect(() => {
    if (origin.toLocaleLowerCase() === 'inter') {
      if (!resultSend?.errors || !resultSend?.errors.length) {
        try {
          interShopping({
            orderRef: paymentType === PaymentType.PICPAY ? resultSend.fastCheckoutPicpay?.orderId : resultSend.fastCheckout?.orderId,
            currency: 'BRL',
            total: total,
            orderDateTime: new Date(),
            paymentConfirmed: true,
            paymentType: paymentType
          })
        } catch (err) {
          console.log(err)
        }
      }
    }
  }, [resultSend])

  const submit = async () => {
    //Função baseada na função já existente no yacaré, devido a deadline e impossibilidade de alterar o mono.
    try {
      setLoading(true);
      const {
        firstName,
        lastName,
        document,
        birthdate,
        email,
        phone
      } = personalData;

      const utm_source = Cookies.get(SOURCE);
      const utm_medium = Cookies.get(MEDIUM);
      const utm_campaign = Cookies.get(CAMPAIGN);
      const utm_term = Cookies.get(TERM);
      const utm_content = Cookies.get(CONTENT);

      if (!gtmCookie) {
        getGtmCookie();
      }

      // const {
      //   code,
      //   number: numberAddress,
      //   city,
      //   neighborhood,
      //   state,
      //   street,
      //   complement
      // } = deliveryAddress;

      const cleanDocument = document.replace(/\D+/g, "");
      const fullName = `${firstName.trim()} ${lastName.trim()}`;

      if (paymentType === PaymentType.CREDIT_CARD && !creditCard) {
        toast.error("Por favor informe os dados do cartão de crédito");
        setLoading(false);
        return;
      }

      if (!deliveryAddress) {
        toast.error("Por favor informe o endereço de entrega");
        setLoading(false);
        return;
      }

      const { installments } = creditCard;

      if (paymentType === PaymentType.CREDIT_CARD) {
        const card = {
          card_number: creditCard.number,
          card_cvv: creditCard.cvv,
          card_holder_name: creditCard.holderName,
          card_expiration_date: creditCard.expirationDate
        };

        const { card: cardValidation } = pagarme.validate({ card });

        const errors: string[] = [];
        for (const validationKey in cardValidation) {
          if (validationKey === "brand") {
            continue;
          }

          const validationStatus = cardValidation[validationKey];

          if (!validationStatus) {
            switch (validationKey) {
              case "card_number": {
                errors.push("Número do cartão inválido.");
                break;
              }
              case "card_holder_name": {
                errors.push("Nome do titular inválido.");
                break;
              }
              case "card_expiration_month": {
                errors.push("Mês de expiração inválido.");
                break;
              }
              case "card_expiration_year": {
                errors.push("Ano de expiração inválido.");
                break;
              }
              case "card_expiration_date": {
                errors.push("Data de expiração inválido.");
                break;
              }
              case "card_cvv": {
                errors.push("CVV (Código de segurança) inválido.");
                break;
              }
              default: {
                break;
              }
            }
          }
        }

        if (errors.length > 0) {
          errors.forEach(error => toast.error(error));
          setLoading(false);
          return;
        }
      }

      const origin = Cookies.get(ORIGIN_FAST_CHECKOUT) ?? ''
      dataLayer.push({
        event: "add_payment_info",
        origin,
        ecommerce: {
          shipping_tier: "Correios",
          payment_type: paymentType,
          origin
        }
      });
      // analytics.track("Payment Info Added", {
      //   shipping_tier: "Correios",
      //   payment_type: paymentType,
      //   origin
      // });
      const vendor = Cookies.get(VENDOR_FAST_CHECKOUT);
      const data = queryParams()
      const loyaltyPlan = data.loyaltyPlan === "none" ? null : data.loyaltyPlan
      let variables: any = {
        items: items.map(item => {
          return {
            paymentMode: item.paymentMode,
            quantity: item.quantity,
            product: item.product._id,
            startDate: item.startDate.format("YYYY-MM-DD"),
            endDate: item.endDate.format("YYYY-MM-DD"),
            amount: calculateFullPrice({
              price: getPriceByPlan(item.product, item.plan),
              quantity: 1, 
              paymentMode: item.paymentMode, 
              daysCompensatoryMeasure: 0,
              rates: { semesterRate: item.product.semesterRate, quarterlyRate: item.product.quarterlyRate,
                loyaltyRateTwelve: item.product.loyaltyFees?.twelve, loyaltyRateEighteen: item.product.loyaltyFees?.eighteen,
                loyaltyRateTwentyOne: item.product.loyaltyFees?.twentyOne, loyaltyRateTwentyFour: item.product.loyaltyFees?.twentyFour,
                loyaltyRateThirty: item.product.loyaltyFees?.thirty, loyaltyRateThirtySix: item.product.loyaltyFees?.thirtySix
               },
              loyaltyPlan,
            }),
            rates: {
              quarterlyRate: item.product.quarterlyRate || "",
              semesterRate: item.product.semesterRate || ""
            }
          };
        }),
        customer: {
          fullName,
          email,
          document: cleanDocument,
          birthDate: moment
            .tz(birthdate, "UTC")
            .format("DD/MM/YYYY")
            .split("/")
            .reverse()
            .join("-"),
          phone: `+55${phone}`
        },
        utm: {
          utmSource: utm_source,
          utmMedium: utm_medium,
          utmCampaign: utm_campaign,
          utmTerm: utm_term,
          utmContent: utm_content
        },
        shippingAddress: {
          code: deliveryAddress.code.replace("-", ""),
          street: deliveryAddress.street,
          city: deliveryAddress.city,
          state: deliveryAddress.state,
          number: deliveryAddress.number,
          neighborhood: deliveryAddress.neighborhood,
          complement: deliveryAddress.complement
        },
        paymentMethod: paymentType,
        paymentDay,
        billingAddress: {
          code: billingAddressData ? billingAddressData?.code?.replace("-", "") : deliveryAddress.code.replace("-", ""),
          street: billingAddressData ? billingAddressData?.street: deliveryAddress.street,
          city: billingAddressData ? billingAddressData?.city : deliveryAddress.city,
          state: billingAddressData ? billingAddressData?.state : deliveryAddress.state,
          number: billingAddressData ? billingAddressData?.number : deliveryAddress.number,
          neighborhood: billingAddressData ? billingAddressData?.neighborhood : deliveryAddress.neighborhood,
          complement: billingAddressData ? billingAddressData?.complement : deliveryAddress.complement,
        },
        origin: Cookies.get(ORIGIN_FAST_CHECKOUT),
        gtmCookie: gtmCookie,
        installments: paymentType === PaymentType.PIX ? 1 : installments,
        userEmail: email,
        salesFunnel: funnel,
        campaign,
        vendor: vendor !== "undefined" ? vendor : null,
        paymentMode: chosenPaymentMode,
        loyaltyPlan,
      };

      if (paymentType === PaymentType.CREDIT_CARD) {
        Object.assign(variables, {
          cardInfo: cardInfo
        });
      }

      if (coupon) {
        Object.assign(variables, { coupon: coupon.slug });
      }

      if (campaign) {
        const externalChannel = Cookies.get(EXTERNAL_CHANNEL_FAST_CHECKOUT);
        const externalVendor = Cookies.get(EXTERNAL_VENDOR_FAST_CHECKOUT);
        const leadId = Cookies.get(EXTERNAL_LEAD_ID_FAST_CHECKOUT);
        const upgradeId = Cookies.get(UPGRADE_ID_FAST_CHECKOUT);
        const notice = Cookies.get(NOTICE_FAST_CHECKOUT);

        const interClickRef = Cookies.get(INTER_BANK_CLICK_REF);
        Object.assign(variables, {
          externalChannel:
            externalChannel !== "undefined" ? externalChannel : null,
          externalVendor:
            externalVendor !== "undefined" ? externalVendor : null,
          leadId: leadId !== "undefined" && leadId ? leadId : interClickRef !== "undefined" && interClickRef ? interClickRef : null,
          upgradeId: upgradeId !== "undefined" ? upgradeId : null,
          notice: notice !== "undefined" ? notice : null,
        });
      }

      if (paymentType === PaymentType.CREDIT_CARD && !hasCardInfo)
        throw new Error("Erro ao criar o token do cartão");

      const result = await client.mutate({
        mutation:
          paymentType === PaymentType.PICPAY
            ? MUTATION_FAST_CHECKOUT_PICPAY
            : MUTATION_FAST_CHECKOUT,
        variables
      });

      setResultSend(result?.data)

      const short_id = paymentType === PaymentType.PICPAY ? result?.data?.fastCheckoutPicpay?.shortId : result?.data?.fastCheckout?.shortId

      const lead_id = Cookies.get(EXTERNAL_LEAD_ID_FAST_CHECKOUT) ?? ''


      if (paymentType === PaymentType.CREDIT_CARD) {
        try {
          if (result?.data?.fastCheckout) {
            const { orderId, shortId } = result.data.fastCheckout;
            setFinishedProduct({
              product: items[0].product,
              total,
              installments,
              shortId,
              finishedMoment: new Date()
            });
            sendDataLayerPurchasedOrder({ orderId }, PaymentType.CREDIT_CARD);
            resultPaymentCreditCard(result);
          } else {
            setPaymentError(true);
            throw new Error(
              "Erro ao realizar o pagamento via cartão de crédito."
            );
          }
        } catch (e) {
          handleErrorSubmit(e, true);
        }
      } else if (paymentType === PaymentType.PIX) {
        try {
          if (result?.data?.fastCheckout) {
            const { orderId, shortId, qrcode } = result.data.fastCheckout;
            setFinishedProduct({
              product: items[0].product,
              total,
              linkPayment: qrcode,
              shortId,
              finishedMoment: new Date()
            });
            sendDataLayerPurchasedOrder({ orderId }, PaymentType.PIX);
            resultPaymentPix();
          } else {
            throw new Error("Erro ao realizar o pagamento via pix.");
          }
        } catch (e) {
          if (e.graphQLErrors) {
            handleErrorSubmit(e, false);
          }
        }
      } else if (paymentType === PaymentType.PICPAY) {
        try {
          if (result?.data?.fastCheckoutPicpay) {
            const {
              orderId,
              shortId,
              paymentUrl
            } = result.data.fastCheckoutPicpay;
            setFinishedProduct({
              product: items[0].product,
              total,
              linkPayment: paymentUrl,
              shortId,
              finishedMoment: new Date()
            });
            sendDataLayerPurchasedOrder({ orderId }, PaymentType.PICPAY);
            resultPaymentPix();
          } else {
            throw new Error("Erro ao realizar o pagamento via picpay.");
          }
        } catch (e) {
          handleErrorSubmit(e, false);
        }
      }
      googleTag("generated_order", {
        send_to: "G-MFSWHTC64V",
        short_id,
        origin: origin,
        lead_id,
      })
    } catch (err) {
      console.error(err);
      if (err?.graphQLErrors && err?.graphQLErrors.length > 0) {
        toast.error(err.graphQLErrors[0].message);
      } else {
        setPaymentError(true);
        toast.error(
          `Erro ao finalizar pedidos, por favor verifique seus dados. ${err}`
        );
      }
      setTimeout(() => {
        cleanCreditCard(true);
      }, 2000);
      setLoading(false);
    }
  };

  const sendDataLayerPurchasedOrder = (data: any, type: PaymentType) => {
    const allowedOrigins = ['new-site', 'email-campaign', 'social-campaign'];

    if (!allowedOrigins.includes(origin)) {
      return;
    }

    if (type === PaymentType.CREDIT_CARD) {
      dataLayer.push({
        event: "purchase",
        origin,
        ecommerce: {
          transaction_id: data.orderId,
          type,
          currency: "BRL",
          value: total.toFixed(2),
          items: [formatProductDataLayer(items[0].product)],
          coupon: (coupon && coupon.slug) || "",
          origin
        }
      });
    }

    analytics.track("Order Completed", {
      transaction_id: data.orderId,
      type,
      currency: "BRL",
      value: total.toFixed(2),
      items: [formatProductDataLayer(items[0].product)],
      coupon: (coupon && coupon.slug) || "",
      origin
    });
  };

  const handleErrorSubmit = (e: any, isRedirect: boolean) => {
    if (e.graphQLErrors) {
      const errs: string[] = [];

      e.graphQLErrors.map((error: GraphQLError) => {
        errs.push(error.message);
        if (error.message === "pending_review") {
          toast.info(
            "Seu pagamento está em revisão manual pela nossa equipe. Aguarde ou entre em contato pelo chat para mais informações."
          );
          if (isRedirect) {
            redirect("/");
          }
          cleanCart();
        } else {
          setPaymentError(true);
          toast.error("Transação não autorizada. Entre em contato pelo chat!");
        }
      });

      setLoading(false);
    } else {
      throw e;
    }
  };

  const resultPaymentCreditCard = (result: any) => {
    if (result.data.fastCheckout.orderId !== "") {
      cleanCart();
      setMissingPayment(false);
      setMissingOrderId("");
      setTransactionId("");
      setOrderConfirmation(true);
      setLoading(false);
      redirect("/pedido-realizado");

      if (
        result.data.fastCheckout.message &&
        result.data.fastCheckout.message === "pending_review"
      ) {
        toast.info(
          "Seu pagamento está em revisão manual pela nossa equipe. Aguarde ou entre em contato pelo chat para mais informações."
        );
      }
    } else {
      setTotal(result.data.fastCheckout.secondValue);
      setMissingPayment(true);
      setMissingOrderId(result.data.fastCheckout.orderId);
      setTransactionId(result.data.fastCheckout.transactionId);
      setLoading(false);
    }
  };

  const resultPaymentPix = () => {
    setOrderConfirmation(true);
    setLoading(false);
    redirect("/pedido-realizado");
    cleanCart();
  };

  const redirect = (path?: string) => {
    return history.push(path ? path : "/");
  };

  const renderFooter = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    if (dataId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${Config.RAZZLE_FAST_CHECKOUT_API}/data/${dataId}`);
          setPayloadFCApi(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      if (dataId) {
        fetchData();
      }
    }
  }, [dataId])

  useEffect(() => {
    if (payloadFCApi) {
      setAblePreviousStep(true)
      handlePaymentStep();
    }
  }, [payloadFCApi])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentTab]);

  return (
    <>
      {displayAnimation ? (
        <AnimationContainer>
          <img src={animation} alt="animation" />
        </AnimationContainer>
      ) : (
        <Box height="100vh">
          <WhatsappBalloon />
          <Box minHeight="15vh" width="100%">
            <Header liveloOrigin={liveloOrigin} />
            <TabMenu />
          </Box>
          <Container maxWidth="lg">
            <Grid
              container
              columns={{ md: 1 }}
              paddingTop={1}
              paddingBottom={4}
              gap={3}
              sx={theme => ({
                [theme.breakpoints.up("sm")]: {
                  justifyContent: "center"
                }
              })}
            >
              <Grid item md>
                <ProductShowcase
                  product={selectedProduct}
                  paymentMode={chosenPaymentMode}
                  score={liveloScore}
                  clubScore={liveloClubScore}
                  liveloOrigin={liveloOrigin}
                  recurrenceValue={paymentModeValues.recurrenceValue}
                  loyaltyPlan={loyaltyPlanSetted}
                />
              </Grid>
              <Grid item xs md>
                <TabPanel
                  value={currentTab}
                  index={TabsCheckoutKey.PERSONAL_DATA}
                >
                  {!adminPaymentModeAvailability && !paymentModeAvailability && (
                    <Stack>
                      <ChooseSubscriptionPlan
                        changeSubscriptionPlan={changeSubscriptionPlan}
                        paymentMode={chosenPaymentMode}
                        setPaymentMode={setChosenPaymentMode}
                        paymentModeValues={paymentModeValues}
                      />
                    </Stack>
                  )}
                  <Stack sx={{ width: "100%" }}>
                    <PersonalDataFastCheckout
                      cart={selectedProduct}
                      valueForDataLayer={valueForDataLayer}
                      setUserData={setUserData}
                      invalidDocument={invalidDocument}
                    />
                    <input
                      id="gtm-cookie-input"
                      className="gtm-cookie-input"
                      type="text"
                      name="gtm-cookie-input"
                      hidden
                    />
                  </Stack>
                </TabPanel>
                <TabPanel value={currentTab} index={TabsCheckoutKey.ADDRESS}>
                  <Box>
                    <DeliveredAddress
                      product={selectedProduct}
                      setAddressData={setAddressData}
                      setBillingAddressData={setBillingAddressData}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={currentTab} index={TabsCheckoutKey.PAYMENT}>
                  {selectedProduct && (
                    <Box sx={{ width: "100%" }}>
                      <PaymentStep
                        paymentError={paymentError}
                        product={selectedProduct}
                        paymentMode={chosenPaymentMode}
                        setPaymentDay={setPaymentDay}
                      />
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={currentTab} index={TabsCheckoutKey.SUMMARY}>
                  {selectedProduct && (
                    <Box sx={{ width: "100%" }}>
                      <ResumeCheckout
                        liveloScore={liveloScore}
                        liveloClubScore={liveloClubScore}
                        liveloOrigin={liveloOrigin}
                        product={selectedProduct}
                        loyaltyPlan={loyaltyPlanSetted}
                        proceedNextStep={setCheckoutFinished}
                        recurrenceValue={paymentModeValues.recurrenceValue}
                        paymentMode={chosenPaymentMode}
                      />
                    </Box>
                  )}
                </TabPanel>
                <Box
                  my={3}
                  display="flex"
                  alignItems="center"
                  gap={3}
                  sx={theme => ({
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }
                  })}
                >
                  {currentTab !== TabsCheckoutKey.PERSONAL_DATA && (
                    <Box>
                      <BackStepButton
                        changeTab={changeTab}
                        currentTab={currentTab}
                      />
                    </Box>
                  )}
                  <Box
                    width="100%"
                    minWidth={326}
                    sx={theme => ({
                      [theme.breakpoints.up("md")]: { maxWidth: 326 }
                    })}
                  >
                    <NextStepButton
                      liveloScore={liveloScore}
                      liveloOrigin={liveloOrigin}
                      disabled={nextStepDisabled}
                      nextStep={handleNextStep}
                      loading={loading}
                      selectedProduct={selectedProduct}
                    />
                  </Box>
                </Box>
                {!renderFooter && (
                  <Box display="flex" justifyContent="center">
                    <Footer />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default connect(
  (state: IAppState) => ({
    loading: state.general.loading,
    personalDataAlreadySaved: state.fastCheckout.personalDataAlreadySaved,
    currentTab: state.fastCheckout.currentTab,
    paymentType: state.fastCheckout.paymentType,
    deliveryAddress: state.fastCheckout.deliveredAddress,
    creditCard: state.fastCheckout.creditCard,
    personalData: state.fastCheckout.personalData,
    cardInfo: state.fastCheckout.creditCard.cardInfo,
    campaign: state.fastCheckout.campaign,
    cart: state.cart,
    subTotal: state.cart.subtotal,
    total: state.cart.total,
    finishedCheckout: state.fastCheckout.finishedCheckout,
    logged: state.user.logged
  }),
  (dispatch: Dispatch<AnyAction>) => ({
    setLoading: (state: boolean) => dispatch(SET_LOADING(state)),
    setPersonalData: (value: IPersonalDataFastCheckout) =>
      dispatch(SET_USER_PERSONAL_DATA(value)),
    addToCart: (cartItem: ICartItem) => dispatch(ADD_TO_CART(cartItem)),
    addCoupon: (coupon: IPublicCoupon) => dispatch(ADD_COUPON(coupon)),
    changeSubscriptionPlan: (paymentMode: PaymentMode) =>
      dispatch(CHANGE_SUBSCRIPTION_PLAN(paymentMode)),
    cleanCart: () => dispatch(CLEAR_CART()),
    changeTab: (value: TabsCheckoutKey) =>
      dispatch(SET_CURRENT_TAB_FAST_CHECKOUT(value)),
    setDeliveredAddress: (value: IUserAddress) =>
      dispatch(SET_DELIVERED_ADDRESS_FAST_CHECKOUT(value)),
    cleanCheckout: () => dispatch(CLEAN_FAST_CHECKOUT()),
    setHasDiscount: (value: boolean) =>
      dispatch(SET_HAS_DISCOUNT_FAST_CHECKOUT(value)),
    setTotal: (total: number) => dispatch(SET_TOTAL(total)),
    setFinishedProduct: (checkout: IFinishedCheckout) =>
      dispatch(SET_FINISHED_PRODUCT_FAST_CHECKOUT(checkout)),
    setMissingPayment: (missingPayment: boolean) =>
      dispatch(SET_MISSING_PAYMENT(missingPayment)),
    setMissingOrderId: (missingOrderId: string) =>
      dispatch(SET_MISSING_ORDER_ID(missingOrderId)),
    setTransactionId: (transactionId: string) =>
      dispatch(SET_TRANSACTION_ID(transactionId)),
    setOrderConfirmation: (orderConfirmation: boolean) =>
      dispatch(SET_ORDER_CONFIRMATION(orderConfirmation)),
    cleanCreditCard: (value?: boolean) =>
      dispatch(CLEAN_CREDIT_CARD_FAST_CHECKOUT(value)),
    loadDataFromLocalStorage: () => dispatch(LOAD_DATA_FROM_LOCAL_STORAGE()),
    setCampaign: (campaign: string | null) =>
      dispatch(SET_CAMPAIGN_FAST_CHECKOUT(campaign)),
    logout: () => dispatch(LOGOUT_USER()),
    addCouponCheckout: (coupon: IPublicCoupon) =>
      dispatch(SET_COUPON_FAST_CHECKOUT(coupon)),
    addOriginCheckout: (origin: string) =>
      dispatch(SET_ORIGIN_FAST_CHECKOUT(origin)),
    setNewAccount: (value: boolean) =>
      dispatch(SET_NEW_ACCOUNT_FAST_CHECKOUT(value)),
    setPersonalDataAlreadySaved: (value: boolean) =>
      dispatch(SET_PERSONAL_DATA_ALREADY_SAVED(value)),
    setTypePayment: (value: PaymentType) =>
      dispatch(SET_TYPE_PAYMENT_FAST_CHECKOUT(value)),
    setRecValue: (value: number) =>
      dispatch(SET_RECURRENCE_VALUE_FAST_CHECKOUT(value)),
    setPaymentModeValue: (value: number) =>
      dispatch(SET_PAYMENT_MODE_FAST_CHECKOUT(value)),
  })
)(withRouter(withApollo(FastCheckout)));
